export const Button = ({
  handleClick,
  text,
  cx,
}: {
  handleClick: () => void;
  text: string;
  cx?: string;
}) => {
  return (
    <div className={`w-full flex items-center justify-center ${cx}`}>
      <button
        onClick={handleClick}
        className="border w-[205px] border-light-text-tertiary p-[18px] rounded-full"
      >
        <p className="text-light-text-tertiary text-sm font-bold">{text}</p>
      </button>
    </div>
  );
};
